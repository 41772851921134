import Comp from './components/comp'


function App() { 
  return (
    <div>
    <h1> stocks </h1>
    testing cloudflare workers and pages 
    <Comp path="/"/>
    </div>
  );
}

export default App;
