import React, { useEffect, useState } from "react";

const Comp = ({ id }) => {

  const [posts, setPosts] = useState({});

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://kv2json.skoien.workers.dev"
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);


  return (
    <div> ether: {posts.eth} </div>
  );
};

export default Comp;
